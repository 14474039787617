<template>
  <v-form @submit.prevent="updatePdf">
    <v-container>
      <Loading v-if="isLoading" />
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <thead>
              <tr>
                <th class="font-weight-bold">
                  {{ $t('global.order') + " " + item.orderId }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
              >
                <td>
                  <v-col
                    ref="verifyNotes"
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('booking.visitConsent') }}
                    </h5>
                    <div
                      v-if="item.verifyNote"
                      class="d-flex">
                      <h5
                        v-if="!file"
                        class="data text-right  text-bold mr-3"
                        :class="$route.query.verifynote === 'true' && $route.query.type === 'group' ? 'white--text ' : 'green--text '"
                        @click="openLink(item.verifyNote)">
                        {{ $t('global.view') }}
                      </h5>
                      <h5
                        v-if="!file"
                        class="data text-right text-bold px-3"
                        :class="$route.query.verifynote === 'true' && $route.query.type === 'group' ? 'white--text ' : 'blue--text '"
                        @click="handleFileImport">
                        {{ $t('global.edit') }}
                      </h5>
                    </div>
                    <h5
                      v-if="!file && !item.verifyNote"
                      class="data text-right blue--text text-bold"
                      @click="handleFileImport">
                      {{ $t('booking.attachFile') }}
                    </h5>
                    <div
                      v-if="file"
                      class="d-flex">
                      <h5
                        class="data text-right green--text text-bold mr-2"
                        @click="updatePdf">
                        {{ $t('booking.upload') }}
                      </h5>
                      <h5
                        class="data text-right red--text text-bold"
                        @click="file = ''">
                        {{ $t('booking.clear') }}
                      </h5>
                    </div>
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="application/pdf"

                      @change="onFileChanged($event)"
                    />
                  </v-col>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            :disabled="!file"
            type="submit"
            class="mb-2"
            depressed
            block
            color="success">
            {{ $t('global.submit') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-dialog
        v-model="isDialog"
        width="700">
        <img
          class="image-preview"
          :src="previewImage"
        />
      </v-dialog>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
// import AddImage from './components/AddImage.vue'

export default {
  components: {
    Loading
  },
  data () {
    return {
      isDialog: false,
      isLoading: false,
      previewImage: '',
      file: '',
      publicUrl: '',
      item: {}
    }
  },
  mounted () {
    // this.checkLogined()
    this.getOrder()
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage'
    }),
    async getOrder () {
      try {
        this.isLoading = true
        const {
          data: { data }
        } = await this.axios.get(
          `${process.env.VUE_APP_API_URL}/order-group/${this.$route.params.orderId}`
        )
        this.item = data
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async updatePdf () {
      try {
        this.isLoading = true
        if (this.file) {
          const formData = new FormData()
          formData.append('file', this.file)
          const { data: { data } } = await this.axios({
            method: 'post',
            url: `${process.env.VUE_APP_API_URL}/upload`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData
          })
          this.publicUrl = data.publicUrl
        }

        await this.axios({
          method: 'patch',
          url: `${process.env.VUE_APP_API_URL}/order-group/${this.$route.params.orderId}`,
          data: { verifyNote: this.publicUrl }
        })
        this.file = ''
        this.publicUrl = ''
        await this.getOrder()
        this.$toast.success('ดำเนินการสำเร็จ', {
            position: 'top-right',
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: false,
            icon: true,
            rtl: false
          })
        setTimeout(() => {
          this.$router.push('/history')
        }, 2000)
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    openImage (url) {
      this.isDialog = true
      this.previewImage = url
    },
    openLink (link) {
      window.open(link)
    },
    handleFileImport () {
      this.$refs.uploader.click()
    },
    clearImage (index) {
      delete this.items[index].image
      delete this.items[index].preview
      this.$forceUpdate()
    },
    onFileChanged (event) {
      if (event.target.files[0]) {
        this.file = event.target.files[0]
        this.$refs.uploader.value = ''
      } else {
        this.file = ''
      }
    },
    async checkLogined () {
      try {
        const { member } = JSON.parse(localStorage.getItem('member'))
        const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/member/${member.memberId}`)
        if (!data.member) {
          localStorage.removeItem('member')
          this.$router.push('/register')
        }
      } catch (error) {
        console.error(error)
      }
    },
    successAlert () {
      try {
        this.$toast('My toast content', {
          timeout: 2000
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file-input-image {
  padding: 0 !important;
  ::v-deep {
    .v-input__prepend-outer {
      display: none;
    }
    .v-input__slot::before {
      display: none;
    }
  }
}
.image-preview {
  max-height: 700px;
  max-width: 700px;
}
</style>
